import clsx from 'clsx'
import React, { useEffect, useMemo, useState } from 'react'

import { useForm, Controller } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { CountrySelect } from '../../components/FieldSelect'

import { useCountryStateContext } from '../../contexts/CountryContext'
import { getLocaleValue, useCMSContext } from '@happy-rabbit/gatsby-sanity-cms'
import { useCartStateContext } from '../../contexts/CartContext'
import FormErrors from './FormErrors'
import { useShopify } from '../../utils/shopify-api/shopifyContext'
import { deleteCart } from '../../utils/shopify-api/shopifyApi'
import {
  parsePhoneNumberFromString,
  getExampleNumber,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js'
import metadata from 'libphonenumber-js/metadata.min.json'
const ShippingAddress = (props) => {
  const {
    checkoutErrors,
    forCompany = false,
    formIsReadyToSubmit,
    setFormIsReadyToSubmit,
    setFormHasBeenSubmitted,
  } = props

  const { lang } = useCMSContext()
  const { country, countryCode } = useCountryStateContext()
  const { cart } = useCartStateContext()

  // Get and parse checkout data from sessionStorage
  const storedCheckoutData =
    typeof window !== 'undefined'
      ? sessionStorage.getItem('checkoutData')
      : null

  const checkoutData = useMemo(() => {
    try {
      return storedCheckoutData ? JSON.parse(storedCheckoutData) : null
    } catch (e) {
      console.error('Failed to parse checkout data:', e)
      return null
    }
  }, [storedCheckoutData])

  const {
    handleUpdateShippingAddress,
    cart: newCart,
    handleGoToCheckout,
    clearCart,
    getCart,
  } = useShopify()

  const {
    control,
    formState,
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })
  const { errors = {} } = formState
  useEffect(() => {
    const newCartAddress = newCart?.delivery?.addresses?.[0]?.address
    const vatNumber = newCart?.attributes?.find(
      (attr) => attr.key === 'vatNumber'
    )?.value

    reset({
      firstName: checkoutData?.firstName,
      lastName: checkoutData?.lastName,
      phone: checkoutData?.phone,
      company: checkoutData?.company,
      address1: checkoutData?.address1,
      address2: checkoutData?.address2,
      city: checkoutData?.city,
      zip: checkoutData?.zip,
      province: checkoutData?.provinceCode,
      vatNumber,
      country: country?.slug?.current,
    })
  }, [country, reset, newCart])

  const countryOptions = useMemo(
    () =>
      country
        ? [
            {
              label: getLocaleValue(country.name, lang),
              value: country.slug.current,
              country,
            },
          ]
        : [],
    [country, lang]
  )

  const [cachedVat, setCachedVat] = useState(null)
  // useEffect(() => {
  //   if (!forCompany) return

  //   const customAttributes = cart?.checkout?.customAttributes || []
  //   const vatNumber = customAttributes.find((ca) => ca.key === 'vatNumber')
  //   if (vatNumber && vatNumber.value !== cachedVat) {
  //     setCachedVat(vatNumber.value)
  //     setValue('vatNumber', vatNumber.value)
  //   }
  // }, [cart, cachedVat])

  const onSubmit = async (data) => {
    if (data.phone && phoneValidation(data.phone, countryCode)) {
      data.phone = phoneValidation(data.phone, countryCode).number
    }

    if (typeof window !== 'undefined') {
      sessionStorage.setItem('checkoutData', JSON.stringify(data))
    }

    try {
      await handleUpdateShippingAddress(data)
      setFormHasBeenSubmitted(true)
      console.log('Form has been submitted')
      console.log(data)
    } catch (err) {
      console.log('Error updating shipping address:', err)

      setFormHasBeenSubmitted(false)
    }
  }

  useEffect(() => {
    if (formIsReadyToSubmit) {
      handleSubmit(onSubmit)()
      setFormIsReadyToSubmit(false)
    }
  }, [formIsReadyToSubmit, handleSubmit])
  const phoneValidation = (phoneNumber, countryCode = 'SE') => {
    if (!phoneNumber) return null
    try {
      // First check if the number is valid and possible using direct methods
      const isPossible = isPossiblePhoneNumber(phoneNumber, countryCode)
      const isValid = isValidPhoneNumber(phoneNumber, countryCode)

      if (isPossible && isValid) {
        const parsedNumber = parsePhoneNumberFromString(
          phoneNumber,
          countryCode
        )
        // Return the formatted E.164 international format (e.g. +46701234567)
        return parsedNumber.format('E.164')
      } else {
        // Check length validation for more specific error
        const lengthValidation = validatePhoneNumberLength(
          phoneNumber,
          countryCode
        )
        console.log('Phone validation failed:', lengthValidation)
        return null
      }
    } catch (error) {
      console.error('Phone validation error:', error)
      return null
    }
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="md:grid md:grid-cols-3 gap-4">
          <label className="block text-gray-700 text-sm mb-2">
            <FormattedMessage
              id="checkout.shippingAddress.firstName"
              description="First Name"
              defaultMessage="First Name"
            />
            <input
              {...register('firstName', {
                required: 'First name is required',
                minLength: {
                  value: 2,
                  message: 'First name must have at least 2 characters',
                },
              })}
              className={clsx(
                'block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
                { 'border-red-500': false }
              )}
              type="text"
              // onBlur={handleSubmit(updateShippingAddress)}
            />
            {formState.errors.firstName && (
              <p className="text-red-500 text-xs italic">
                {formState.errors.firstName.message}
              </p>
            )}
          </label>
          <label className="block text-gray-700 text-sm mb-2">
            <FormattedMessage
              id="checkout.shippingAddress.lastName"
              description="Last Name"
              defaultMessage="Last Name"
            />
            <input
              {...register('lastName', {
                required: 'Last name is required',
                minLength: {
                  value: 2,
                  message: 'Last name must have at least 2 characters',
                },
              })}
              className={clsx(
                'block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
                { 'border-red-500': false }
              )}
              type="text"
              // onBlur={handleSubmit(updateShippingAddress)}
            />
            {formState.errors.lastName && (
              <p className="text-red-500 text-xs italic">
                {formState.errors.lastName.message}
              </p>
            )}
          </label>
          <label className="block text-gray-700 text-sm mb-2">
            <FormattedMessage
              id="checkout.shippingAddress.phone"
              description="Phone"
              defaultMessage="Phone"
            />

            <input
              {...register('phone', {
                validate: (value) => {
                  if (!value) return true // Optional field
                  return (
                    !!phoneValidation(value, countryCode) ||
                    'Please enter a valid phone number'
                  )
                },
              })}
              className={clsx(
                'block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
                { 'border-red-500': !!formState.errors.phone }
              )}
              type="tel"
            />
            {formState.errors.phone && (
              <p className="text-red-500 text-xs italic">
                {formState.errors.phone.message}
              </p>
            )}
          </label>
        </div>
        {/* <div className="">
          <FormErrors
            checkoutErrors={checkoutErrors}
            fields={[
              'shippingAddress.firstName',
              'shippingAddress.lastName',
              'shippingAddress.phone',
            ]}
            intlPrefix={'checkout.'}
          />
        </div> */}

        {forCompany ? (
          <>
            <label className="block text-gray-700 text-sm mb-2">
              <FormattedMessage
                id="checkout.shippingAddress.company"
                description="The company name"
                defaultMessage="Company"
              />
              <input
                {...register('company')}
                className={clsx(
                  'block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
                  { 'border-red-500': false }
                )}
                type="text"
                // onBlur={handleSubmit(updateShippingAddress)}
              />
            </label>
            <div className="">
              <FormErrors
                checkoutErrors={checkoutErrors}
                fields={['shippingAddress.company']}
                intlPrefix={'checkout.'}
              />
            </div>

            <label className="block text-gray-700 text-sm mb-2">
              <FormattedMessage
                id="checkout.company-vat-number"
                description="The company vat number"
                defaultMessage="VAT Number"
              />
              <input
                {...register('vatNumber', {
                  required: 'VAT number is required',
                })}
                className={clsx(
                  'block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
                  { 'border-red-500': false }
                )}
                required={true}
                type="text"
                // onBlur={handleSubmit(updateShippingAddress)}
              />
              {formState.errors.vatNumber && (
                <p className="text-red-500 text-xs italic">
                  {formState.errors.vatNumber.message}
                </p>
              )}
            </label>
          </>
        ) : null}

        <label className="block text-gray-700 text-sm mb-2">
          <FormattedMessage
            id="checkout.shippingAddress.address1"
            description="The street name and house number"
            defaultMessage="Street"
          />
          <input
            {...register('address1', {
              required: 'Street is required',
            })}
            className={clsx(
              'block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
              { 'border-red-500': false }
            )}
            type="text"
          />
          {formState.errors.address1 && (
            <p className="text-red-500 text-xs italic">
              {formState.errors.address1.message}
            </p>
          )}
        </label>

        <div className="">
          <FormErrors
            checkoutErrors={checkoutErrors}
            fields={['shippingAddress.address1']}
            intlPrefix={'checkout.'}
          />
        </div>

        <label className="block text-gray-700 text-sm mb-2">
          <FormattedMessage
            id="checkout.shippingAddress.address2"
            description="The second address line"
            defaultMessage="Address 2"
          />
          <input
            {...register('address2')}
            className={clsx(
              'block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
              { 'border-red-500': false }
            )}
            type="text"
            // onBlur={handleSubmit(updateShippingAddress)}
          />
        </label>

        <div className="">
          <FormErrors
            checkoutErrors={checkoutErrors}
            fields={['shippingAddress.address2']}
            intlPrefix={'checkout.'}
          />
        </div>

        <div
          className={clsx(
            'grid md:flex gap-4',
            country?.slug?.current === 'se'
              ? 'md:grid-cols-2'
              : 'md:grid-cols-3'
          )}
        >
          <label className={clsx('block text-gray-700 text-sm mb-2')}>
            <FormattedMessage
              id="checkout.shippingAddress.zip"
              description="The Zip code"
              defaultMessage="Zip / Postal"
            />
            <input
              {...register('zip')}
              className={clsx(
                'block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
                { 'border-red-500': false }
              )}
              type="text"
              required={true}
              // onBlur={handleSubmit(updateShippingAddress)}
            />
            {formState.errors.zip && (
              <p className="text-red-500 text-xs italic">
                {formState.errors.zip.message}
              </p>
            )}
          </label>
          <label className={clsx('block text-gray-700 text-sm mb-2')}>
            <FormattedMessage
              id="checkout.shippingAddress.city"
              description="The city"
              defaultMessage="City"
            />
            <input
              {...register('city', {
                required: 'City is required',
              })}
              className={clsx(
                'block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
                { 'border-red-500': false }
              )}
              type="text"
              required={true}
              // onBlur={handleSubmit(updateShippingAddress)}
            />
            {formState.errors.city && (
              <p className="text-red-500 text-xs italic">
                {formState.errors.city.message}
              </p>
            )}
          </label>

          {country?.slug?.current === 'se' ? null : (
            <label className="block text-gray-700 text-sm mb-2">
              <FormattedMessage
                id="checkout.shippingAddress.province"
                description="The Province"
                defaultMessage="State / Province"
              />
              <input
                {...register('province')}
                className={clsx(
                  'block shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
                  { 'border-red-500': false }
                )}
                type="text"
                // onBlur={handleSubmit(updateShippingAddress)}
                // onBlur={handleSubmit(updateCartShippingAddress)}
              />
            </label>
          )}
        </div>
        <div className="">
          <FormErrors
            checkoutErrors={checkoutErrors}
            fields={[
              'shippingAddress.city',
              'shippingAddress.zip',
              'shippingAddress.province',
            ]}
            intlPrefix={'checkout.'}
          />
        </div>

        <Controller
          name="country"
          control={control}
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <CountrySelect
              {...field}
              attribute={'country'}
              defaultValue={field.value}
              disabled={true}
              errors={errors}
              label={
                <FormattedMessage
                  id="checkout.shippingAddress.country"
                  description="Country Label"
                  defaultMessage={`Country`}
                />
              }
              options={countryOptions}
            />
          )}
        />
        <div className="">
          <FormErrors
            checkoutErrors={checkoutErrors}
            fields={['shippingAddress.country']}
            intlPrefix={'checkout.'}
          />
        </div>
      </form>
    </>
  )
}

export default ShippingAddress
