import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'

import { useCartStateContext } from '../../contexts/CartContext'
import CartLineItems from '../Drawer/CartLineItems'
import CartTotals from '../Drawer/CartTotals'
import { useCountryStateContext } from '../../contexts/CountryContext'
import Button, {
  defaultClassName,
} from '@happy-rabbit/component-library/lib/Button'

import Individual from './Individual'
import Company from './Company'
import Layout from './Layout'

import './index.css'
import { PageLink } from '@happy-rabbit/gatsby-sanity-cms/components'
import SEO from '../SEO'
import { useShopify } from '../../utils/shopify-api/shopifyContext'

const Checkout = (props) => {
  const {
    cart,
    checkoutErrors,
    updateShippingLine,
    getAvailableShippingRates,
  } = useCartStateContext()
  const { includeVAT, setIncludeVAT } = useCountryStateContext()
  const { cart: newCart, handleGoToCheckout } = useShopify()

  const [formIsReadyToSubmit, setFormIsReadyToSubmit] = useState(false)
  const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false)

  // const checkoutErrors = useMemo(() => {
  //   return (cart?.checkout?.userErrors || []).reduce((acc, userError) => {
  //     const fieldName = userError.field.map(f => f.value).join('.');

  //     return {
  //       ...acc,
  //       [fieldName]: acc[fieldName] ? [...acc[fieldName], userError.message] : [userError.message],
  //     };
  //   }, {});
  // }, [cart]);

  // Load all possibl<e shippingZones once
  // useEffect(() => {
  //   if (country && !shippingZone) {
  //     fetch(`/.netlify/functions/getShippingZone?country=${country.slug?.current}`)
  //       .then(res => res.json())
  //       .then(setShippingZone)
  //       .catch(error => console.log(error));
  //   }
  // }, []);

  // Reloads availableShippingRates everytime the totalPrice or the shippingAddress is changed.
  // The first time getAvailableShippingRates is called, it will also load all possible shippingRates
  // from the shippingZone if the current Country.
  // useEffect(() => getAvailableShippingRates(), [
  //   cart?.checkout?.totalPriceV2,
  //   cart?.checkout?.shippingAddress,
  // ])

  // const readyToProceed = useMemo(() => {
  //   return (
  //     cart?.checkout?.email &&
  //     cart?.checkout?.shippingAddress &&
  //     (cart?.data?.availableShippingRates || [])
  //       .map((rate) => rate.handle)
  //       .indexOf(cart?.checkout?.shippingLine?.handle) >= 0
  //   )
  // }, [cart])

  const readyToProceed = true

  // const handlePaymentClick = useCallback(() => {
  //   if (readyToProceed) {
  //     window.location = newCart?.checkoutUrl
  //   }
  // }, [readyToProceed, newCart])

  // Effect to redirect to checkout URL after form submission
  useEffect(() => {
    console.log('formHasBeenSubmitted', formHasBeenSubmitted)
    console.log('formHasBeenSubmitted', newCart)

    if (formHasBeenSubmitted && newCart?.checkoutUrl) {
      handleGoToCheckout()
      setFormHasBeenSubmitted(false)
    }
  }, [formHasBeenSubmitted])

  const handlePaymentClick = () => {
    setFormIsReadyToSubmit(true)
  }

  return (
    <>
      <SEO />

      <div className="container mx-auto">
        <div className="grid md:grid-cols-2 gap-4">
          <div className="px-6 sm:px-0">
            <div className="flex text-center averta-bold">
              <div
                className={clsx(
                  'w-1/2 py-4 border-r border-l border-t border-depalmaGray',
                  {
                    'bg-depalmaGray text-gray-600 cursor-pointer hover:underline': !includeVAT,
                    underline: includeVAT,
                  }
                )}
                onClick={() => setIncludeVAT(true)}
              >
                <FormattedMessage
                  id="checkout.individual"
                  defaultMessage="Individual"
                />
              </div>
              <div
                className={clsx(
                  'w-1/2 py-4 border-r border-l border-t border-depalmaGray',
                  {
                    'bg-depalmaGray text-gray-600 cursor-pointer hover:underline': includeVAT,
                    underline: !includeVAT,
                  }
                )}
                onClick={() => setIncludeVAT(false)}
              >
                <FormattedMessage
                  id="checkout.company"
                  defaultMessage="Company"
                />
              </div>
            </div>

            {includeVAT ? (
              <Individual
                formIsReadyToSubmit={formIsReadyToSubmit}
                setFormHasBeenSubmitted={setFormHasBeenSubmitted}
                setFormIsReadyToSubmit={setFormIsReadyToSubmit}
                cart={cart}
                checkoutErrors={checkoutErrors}
                updateShippingLine={updateShippingLine}
              />
            ) : (
              <Company
                formIsReadyToSubmit={formIsReadyToSubmit}
                setFormHasBeenSubmitted={setFormHasBeenSubmitted}
                setFormIsReadyToSubmit={setFormIsReadyToSubmit}
                cart={cart}
                checkoutErrors={checkoutErrors}
                updateShippingLine={updateShippingLine}
              />
            )}
          </div>

          <div className="px-6 sm:px-0">
            {/* <CartLineItems
              className="overflow-y-scroll flex-auto"
              lineItems={cart?.checkout?.lineItems || []}
            /> */}
            {newCart && (
              <CartLineItems
                className="overflow-y-scroll flex-auto"
                lineItems={newCart.lines.edges}
              />
            )}

            <CartTotals />

            <div className="flex justify-end">
              <div className="flex-1 sm:hidden mr-4">
                <PageLink
                  buttonTheme="ghost"
                  className="w-full text-center"
                  noButtonMargin={true}
                  showAsButton={true}
                  pageConfig={{ slug: { current: '/' } }}
                >
                  <FormattedMessage
                    id="checkout.continue-shopping"
                    defaultMessage="Continue Shopping"
                  />
                </PageLink>
              </div>

              <div className="flex-1 sm:flex-1 sm:flex-grow-0">
                <Button
                  className={`${defaultClassName.replace(
                    'px-8',
                    'px-0 sm:px-8'
                  )} w-full sm:w-auto flex items-center justify-center`}
                  disabled={!readyToProceed}
                  onClick={handlePaymentClick}
                  theme={readyToProceed ? 'royal' : 'ghost'}
                >
                  {cart?.cartRequest ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : null}
                  <FormattedMessage
                    id="checkout.proceed-to-payment"
                    defaultMessage="Proceed to Payment"
                  />
                </Button>
              </div>
            </div>
            <div style={{ fontSize: '14px', marginTop: '15px' }}>
              <FormattedMessage id="checkout.update" />
              <br style={{ marginBottom: '10px' }} />
              <a href="https://www.depalma.se/contact-us">
                https://www.depalma.se/contact-us
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Checkout

export { Layout }
