import React from 'react'
import { FormattedMessage } from 'react-intl'

import ContactDetails from './ContactDetails'
import ShippingAddress from './ShippingAddress'
import ShippingMethods from './ShippingMethods'
import ShippingMethodsNonSEK from './ShippingMethodsNonSEK'

const Company = (props) => {
  const {
    cart,
    checkoutErrors,
    updateShippingLine,
    formIsReadyToSubmit,
    setFormHasBeenSubmitted,
    setFormIsReadyToSubmit,
  } = props

  return (
    <div>
      <div className="tab w-full overflow-hidden">
        <input
          className="absolute opacity-0"
          id="tab-single-one"
          defaultChecked
          type="radio"
          name="tabs2"
        />
        <label
          className="block py-5 leading-normal cursor-pointer"
          htmlFor="tab-single-one"
        >
          <FormattedMessage
            id="checkout.contact-details-and-address"
            defaultMessage="Contact Details"
          />
        </label>
        <div className="tab-content overflow-hidden leading-normal">
          <div className="pb-8">
            <ContactDetails checkoutErrors={checkoutErrors} forCompany={true} />
          </div>
          <div className="pb-8">
            <ShippingAddress
              formIsReadyToSubmit={formIsReadyToSubmit}
              setFormHasBeenSubmitted={setFormHasBeenSubmitted}
              setFormIsReadyToSubmit={setFormIsReadyToSubmit}
              checkoutErrors={checkoutErrors}
              forCompany={true}
            />
          </div>
          {/* <div className="pb-8">
            {cart?.checkout?.currencyCode === 'SEK' ? (
              <ShippingMethods
                cart={cart}
                checkoutErrors={checkoutErrors}
                updateShippingLine={updateShippingLine}
              />
            ) : (
              <ShippingMethodsNonSEK
                cart={cart}
                checkoutErrors={checkoutErrors}
                updateShippingLine={updateShippingLine}
              />
            )}
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Company
